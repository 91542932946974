import Banner from "../Components/Banner"
import Card from "../Components/Card"
import EnquiryForm from "../Components/EnquiryForm"

export default function Home() {
    return(
        <>
        <div className="container mx-auto">
            <div className="lg:grid grid-cols-4 gap-3">
                <div className="col-span-3 rounded">
                    <Banner />
                </div>
                <div className="hidden lg:block">
                    <EnquiryForm />
                </div>
            </div>
        </div>
        <div className="bg-blue-700 text-white py-5 font-medium my-3 px-5 lg:px-64 text-center">
        we offer a wide range of services with quality and prices second to none.
        </div>
        <div className="lg:mb-72">
            <Card />
        </div>
        <div className="mb-96 lg:hidden">
            <EnquiryForm />
        </div>
        </>
    )
}