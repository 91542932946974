
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Banner1 from "../Assets/Images/Banner1.jpg"
import Banner2 from "../Assets/Images/Banner2.jpg"
import Banner3 from "../Assets/Images/Banner3.jpg"
import Banner4 from "../Assets/Images/Banner4.jpg"
import Banner5 from "../Assets/Images/Banner5.jpg"

export default function Banner() {
    return(
        <Carousel interval={3000} autoPlay={true} showStatus={false} showThumbs={false} infiniteLoop={true}>
            <div>
                <img src={Banner1} alt="banner1" className="lg:rounded-md" />
                <p className="legend">Recruitment</p>
            </div>
            <div>
                <img src={Banner2} alt="banner2" className="lg:rounded-md" />
                <p className="legend">Payroll solutions</p>
            </div>
            <div>
                <img src={Banner3} alt="banner3" className="lg:rounded-md" />
                <p className="legend">Training & development</p>
            </div>
            <div>
                <img src={Banner4} alt="banner3" className="lg:rounded-md" />
                <p className="legend">Statutory compliance & audit</p>
            </div>
            <div>
                <img src={Banner5} alt="banner3" className="lg:rounded-md" />
                <p className="legend">Legal services</p>
            </div>
        </Carousel>
    )
}