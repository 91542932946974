import Clients from "../Assets/Images/clients.jpg";

export default function Client() {
  //   const clients = [
  //     "Asset HR Capital Solutions",
  //     "Shri Ram Agencies",
  //     "OneTech Solutions",
  //     "S Cube Digital Marketing",
  //     "SGHRMS Solutions",
  //     "PR Global Exports",
  //     "Nachiyar Enterprises",
  //     "Moon Light Foods",
  //     "Om Sri Bhagavan Industries",
  //     "Nithik Safety Consultant and Solutions",
  //     "Sri Ramajeyam Enterprises",
  //     "Sri Ramprabhu Enterprises",
  //     "MANIKANDAN ENGINEERING WORKS",
  //     "BEVEL 'N' CHAMFER DESIGN STUDIO",
  //     "SRSV CONSTRUCTION",
  //   ];
  return (
    <div className="container mx-auto mb-96">
      <p
        className="text-center mb-16 font-bold text-lg text-white bg-blue-700 w-96 mx-auto py-1 pr-6"
        style={{ clipPath: "polygon(16% 0px, 90% 0px, 75% 100%, 0% 100%)" }}
      >
        SOME OF OUR CLIENTS
      </p>
      {/* <div className="text-center">
                <p className="text-center font-bold text-lg text-white bg-blue-700 w-72 mx-auto py-1 pr-6" style={{clipPath: "polygon(16% 0px, 90% 0px, 75% 100%, 0% 100%)"}}>
                    REPUTED CLIENTS
                </p>
                <div className="flex flex-wrap lg:mx-12 mb-96 justify-center mt-10">
                    {
                        clients.map(e => {
                            return <p className="mx-4 hover:bg-gray-200 rounded-lg p-3 cursor-pointer uppercase md:font-black text-xl font-bold text-gray-700">{e}</p>
                        })
                    }
                </div>
            </div> */}
      <img src={Clients} alt="clients" />
    </div>
  );
}
