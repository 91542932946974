import React from "react"
import { Link, useLocation } from "react-router-dom"

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  const location = useLocation()
  const activeMenuClass = "lg:px-5 py-2 flex items-center  uppercase font-bold leading-snug  hover:opacity-75 lg:border-t-4 lg:-mt-4 lg:pt-5 lg:border-blue-700" 
  const menuClass = "lg:px-5 py-2 flex items-center  uppercase font-bold leading-snug  hover:opacity-75"
  return (
    <>
      <nav className="relative container mx-auto flex flex-wrap items-center justify-between mt-3 border-t-4 border-gray-200 px-2 py-3 mb-3">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <button
              className="text-white ml-auto cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-blue-700 block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col bg-blue-700 w-full font-extrabold mt-2 lg:mt-0 rounded-lg lg:bg-transparent text-white lg:flex-row list-none lg:text-blue-700 text-md">
              <li className="nav-item">
                <Link
                  onClick={() => setNavbarOpen(false)}
                  className={ (location.pathname === "/" ? activeMenuClass : menuClass)+" pl-0"}
                  to="/"
                >
                    <span className="ml-2 lg:ml-0">Home</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => setNavbarOpen(false)}
                  className={ (location.pathname === "/about" ? activeMenuClass : menuClass)+" pl-0"}
                  to="/about"
                >
                    <span className="ml-2">About us</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => setNavbarOpen(false)}
                  className={ (location.pathname === "/service" ? activeMenuClass : menuClass)+" pl-0"}
                  to="/service"
                >
                    <span className="ml-2">Services</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => setNavbarOpen(false)}
                  className={ (location.pathname === "/clients" ? activeMenuClass : menuClass)+" pl-0"}
                  to="/clients"
                >
                    <span className="ml-2">Clients</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => setNavbarOpen(false)}
                  className={ (location.pathname === "/contact" ? activeMenuClass : menuClass)+" pl-0"}
                  to="/contact"
                >
                    <span className="ml-2">contact us</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
