import Rec from "../Assets/Images/Services - recruitment.jpg";
import Tra from "../Assets/Images/Services - Training.jpeg";
import Pay from "../Assets/Images/Services - payroll-services.jpg";
import Leg from "../Assets/Images/Service legal-services.jpg";
import Com from "../Assets/Images/Service Compliance.jpg";
import Lic from "../Assets/Images/Services - Licence work.jpg";

export default function Service() {
  return (
    <div className="max-w-screen mb-96">
      <div className="container mx-auto ">
        <p
          className="text-center font-bold text-lg text-white bg-blue-700 w-48 mx-auto py-1 pr-6"
          style={{ clipPath: "polygon(16% 0px, 90% 0px, 75% 100%, 0% 100%)" }}
        >
          SERVICES
        </p>
      </div>
      <div className="w-screen p-8  mt-2" id="accounting">
        <div className="container mx-auto">
          <p className="text-gray-700 font-bold text-center md:w-3/4 mx-auto">
            We offer the following professional services with competent fee
            structures which provide better value for our clients. We are always
            at your easy reach and assure our best services
          </p>
        </div>
      </div>
      <div className="container mx-auto mt-10 lg:grid grid-cols-2 gap-20 px-5">
        <div id="accounting">
          <img src={Rec} className="  m-auto" alt="Accounts" />
        </div>
        <div className="mt-5 lg:mt-0">
          <h1 className="font-bold text-center text-blue-700 text-xl uppercase">
            recruitment
          </h1>
          <p className="mt-10 mx-autor">
            <ul>
              <li className="list">Campus Hiring's</li>
              <li className="list">Head Hunting (Jr. Positions to COO's)</li>
              <li className="list">Bulk Hiring (3,000+ placements)</li>
              <li className="list">
                Assessments
                <ul className="ml-5">
                  <li>
                    - Logical reasoning, Spatial ability, Numerical ability,
                    Basic Science and English
                  </li>
                  <li>- Psychometric test</li>
                  <li>- Group In Tray exercise I Group discussion</li>
                  <li>- Personal interview (by Client)</li>
                  <li>- Assessment Centre</li>
                  <li>- Competency Development Centre</li>
                </ul>
              </li>
            </ul>
          </p>
        </div>
        <div className="block lg:hidden mt-16">
          <img src={Tra} className="  m-auto" alt="Accounts" />
        </div>
        <div className="" id="finance">
          <h1 className="font-bold mt-5 lg:mt-0 text-center text-blue-700 text-xl uppercase">
            Training & Development
          </h1>
          <p className="mt-5  mx-auto">
            Providing complete training and development solutions to enrich
            employee skills, knowledge and values. Offering solutions such as;
            <ul className="mt-3">
              <li className="list">
                Training Need Identification & Analysis (TNI & TNA)
              </li>
              <li className="list">Annual Training Calendar and budgeting</li>
              <li className="list">
                Soft skills , Behavioral , Technical , Leadership programs and
                Out Bound Trainings
              </li>
              <li className="list">Learning and Development Dashboard</li>
              <li className="list">Monitoring Training Effectiveness</li>
              <li className="list">Skills / Competency Matrix</li>
            </ul>
          </p>
        </div>
        <div className="lg:block hidden my-auto">
          <img src={Tra} className="  m-auto" alt="Accounts" />
        </div>
        <div className="mt-10">
          <img src={Pay} className=" m-auto " alt="tax" />
        </div>
        <div className="my-auto">
          <h1 className="font-bold mt-5 lg:mt-0 text-center text-blue-700 text-xl uppercase">
            Payroll solutions
          </h1>
          <p className="mt-5  mx-auto text-center">
            We provide comprehensive payroll services for companies that value
            flexibility, affordability and responsiveness .Whether your company
            has one employee or hundreds we can customize a specific,
            confidential solutions budgeting to improve operational efficiency
            and keep you in compliance. <br />
            Our software are designed to accommodate all business, not by taking
            a one—size-fits-all approach, but by being highly customizable so we
            can tailor it to match the requirements of your business.
          </p>
        </div>
        <div className="block lg:hidden mt-16">
          <img src={Leg} className=" h-full m-auto" alt="Accounts" />
        </div>
        <div className="" id="finance">
          <h1 className="font-bold mt-5 text-center text-blue-700 text-xl uppercase">
            Legal services
          </h1>
          <p className="mt-5  mx-auto">
            Empanelling our Advocates : <br />
            Lead by{" "}
            <b> Mr.R.Krishnakumar - General Secretary Madras High Court </b>
            <br />
            Our team has an extensive practice in Labour,
            Civil,Consumer,Criminal, Debts Recovery Laws, Intellectual Property
            Laws, Real Estate Law, Deeds & documentation, Legal Opinions and
            Legal Audits. The Team also give special emphasis on recoveries of
            the debts due to the organization, including through the law
            enforcement. <br />
            Our team possesses and implicates an active litigation practice
            regularly by representing our clients in various matters before all
            or any Judicial officers in any court namely High court ,all other
            subordinate courts, consumer forums, Civil and criminal courts,
            Tribunal & Statutory Authority.
          </p>
        </div>
        <div className="lg:block hidden">
          <img src={Leg} className=" h-full m-auto" alt="Accounts" />
        </div>
        <div className="mt-10 lg:my-auto">
          <img src={Com} className=" m-auto " alt="tax" />
        </div>
        <div className="lg:-mt-10">
          <h1 className="font-bold mt-5 lg:mt-0 text-center text-blue-700 text-xl uppercase">
            Statutory compliance
          </h1>
          <p className="mt-5  mx-auto ">
            Labour Law Compliance, Payroll Compliance and also empanelling our
            top advocates of the states at our esteemed client organization for
            handling entire legal proceeding as per requirement <br />
            We are dedicated& give timely support to our client with a high
            level of statutory competences. <br />
            The objective of outsourcing has now shifted from merely saving
            costs to achieving sustained performance improvement <br />
            <br />
            Statutory Compliance Management Framework fosters a secure and
            structured delivery of various compliance services, in alignment
            with highly strategic and tactical functions for end to end
            solutions and services.
            <ul className="mt-3">
              <li className="list">
                Registrations, Renewal, Amendment & Closure
              </li>
              <li className="list">
                Consulting/Advisory & Training related to Labour Laws
              </li>
              <li className="list">
                Labour Enforcement Authorities inspection handling facilitation
              </li>
              <li className="list">Updates on Labour Laws - Amendments</li>
              <li className="list">
                Periodical Remittances & Filing of Returns
              </li>
              <li className="list">Maintenance of Registers & Records</li>
              <li className="list">Display of Abstracts & Notices</li>
              <li className="list">
                Continuous monitoring of Contractors Compliance
              </li>
              <li className="list">Accidents Coordination Activities</li>
              <li className="list">Benefits of Labour Legislations</li>
              <li className="list">
                Continuous monitoring of Contractors Compliance
              </li>
            </ul>
          </p>
        </div>
        <div className="block lg:hidden mt-16">
          <img src={Lic} className="  m-auto" alt="Accounts" />
        </div>
        <div className="" id="finance">
          <h1 className="font-bold mt-5 text-center text-blue-700 text-xl uppercase">
            Licence work
          </h1>
          <p className="mt-5  mx-auto">
            We provide one stop solutions to our clients dealing with Labour
            department, Factories department, Pollution Control Board, Fire
            Department, Health Department, and Corporation handling complete
            list of Registration and renewal of license.
            <ul className="grid grid-cols-2 mt-3">
              <li className="list">Factory License</li>
              <li className="list">S&E Registration</li>
              <li className="list">Trade License</li>
              <li className="list">
                Contract Labour & Interstate Migrant Registration
              </li>
              <li className="list">FSSAI License</li>
              <li className="list">BOCW Registration</li>
              <li className="list">Fire License</li>
              <li className="list">Pollution Control Board NOC</li>
              <li className="list">Catering License</li>
              <li className="list">Hospital/Clinical Registration</li>
            </ul>
          </p>
        </div>
        <div className="lg:block hidden">
          <img src={Lic} className="  m-auto" alt="Accounts" />
        </div>
      </div>
    </div>
  );
}
