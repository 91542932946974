import Vission from "../Assets/Images/vision.svg";
import Mission from "../Assets/Images/mission.svg";
import Value from "../Assets/Images/Icon DICE.png";

export default function About() {
  return (
    <>
      <div className="max-w-screen">
        <div className="text-center">
          <p
            className="text-center font-bold text-lg text-white bg-blue-700 w-48 mx-auto py-1 pr-6"
            style={{ clipPath: "polygon(16% 0px, 90% 0px, 75% 100%, 0% 100%)" }}
          >
            ABOUT US
          </p>
          <p className="mx-5 lg:mx-64 text-lg font-bold text-gray-700 text-center mt-10">
            SG HRM Solutions is a privately owned company with operations all
            over Tamil Nadu, controlled and managed by specialists with over 20
            years experience in the HR & Legal Compliance, we offer a wide range
            of services with quality and prices second to none. <br />
            Our teams are experienced and diverse in their knowledge within the
            industry. We pride ourselves on maintaining close working
            relationships with our customers to ensure delivery of the agreed
            service requirements. <br />
            We offer our clients objective and unbiased advice which is always
            in their best interest. Our knowledge and structure ensures tight
            management control and discipline, along with an unsurpassed quality
            of service. We also understand the need for customers to rely on us
            completely to safeguard their businesses.
          </p>
        </div>
      </div>
      <div className="container mx-auto mt-10 lg:px-20 mb-96">
        <div className="lg:grid grid-cols-2">
          <img className="w-3/4 mx-auto" src={Vission} alt="vision" />
          <div className="my-auto">
            <p className="text-xl uppercase lg:-mt-5 mt-5 text-center text-blue-700 font-bold">
              Vision
            </p>
            <p className="text-lg mt-5 text-center">
              Build a sustainable world class corporate and leaders.
            </p>
          </div>
        </div>
        <div className="lg:grid grid-cols-2 mt-10">
          <div className="my-auto">
            <img
              className="md:w-3/4 mx-auto lg:hidden"
              src={Mission}
              alt="Mission"
            />
            <p className="text-xl uppercase lg:-mt-5 mt-5 text-center text-blue-700 font-bold">
              Mission
            </p>
            <div className="text-lg mt-5 w-3/4 mx-auto text-center">
              Engage with leading corporate for innovative HR Bussiness
              Solutions.
            </div>
          </div>
          <img
            className="w-3/4 mx-auto lg:block hidden"
            src={Mission}
            alt="Mission"
          />
        </div>
        <div className="lg:grid grid-cols-2 mt-10">
          <img className="w-3/4 mx-auto" src={Value} alt="vision" />
          <div className="m-auto w-36">
            <p className="text-xl uppercase lg:-mt-5 mt-5 text-center text-blue-700 font-bold">
              Value
            </p>
            <p className="text-lg mt-5 ml-2">
              <ul className="">
                <li className="list">
                  {" "}
                  <span className="text-blue-700">D</span>iscipline{" "}
                </li>
                <li className="list">
                  {" "}
                  <span className="text-blue-700">I</span>ntegrity{" "}
                </li>
                <li className="list">
                  {" "}
                  <span className="text-blue-700">C</span>ollaboration{" "}
                </li>
                <li className="list">
                  {" "}
                  <span className="text-blue-700">E</span>xcellence{" "}
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
