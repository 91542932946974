import { useHistory } from "react-router-dom";
import Rec from "../Assets/Images/Services - recruitment.jpg";
import Tra from "../Assets/Images/Services - Training.jpeg";
import Pay from "../Assets/Images/Services - payroll-services.jpg";
import Leg from "../Assets/Images/Service legal-services.jpg";
import Com from "../Assets/Images/Service Compliance.jpg";
import Lic from "../Assets/Images/Services - Licence work.jpg";

export default function Card() {
  const history = useHistory();
  const service = [
    {
      name: "RECRUITMENT",
      desc: (
        <ul>
          <li className="list">Head Hunting (Jr. Positions to COO's)</li>
          <li className="list">Bulk Hiring (3,000+ placements)</li>
          <li className="list">Assessments</li>
        </ul>
      ),
      image: Rec,
    },
    {
      name: "TRAINING & DEVELOPMENT",
      desc: (
        <ul>
          <li className="list">Annual Training Calendar and budgeting</li>
          <li className="list">Learning and Development Dashboard</li>
          <li className="list">Monitoring Training Effectiveness</li>
          <li className="list">Skills / Competency Matrix</li>
        </ul>
      ),
      image: Tra,
    },
    {
      name: "PAYROLL SOLUTIONS",
      desc: "We provide comprehensive payroll services for companies that value flexibility, affordability and responsiveness .Whether your company has one employee or hundreds we can customize a specific, confidential solutions budgeting to improve operational efficiency and keep you in compliance.",
      image: Pay,
    },
    {
      name: "LEGAL SERVICES",
      desc: "Empanelling our Advocates Lead by Mr.R.Krishnakumar - General Secretary Madras High Court Our team has an extensive practice in Labour, Civil,Consumer,Criminal, Debts Recovery Laws, Intellectual Property Laws, Real Estate Law, Deeds & documentation, Legal Opinions and Legal Audits. The Team also give special emphasis on recoveries of the debts due to the organization, including through the law enforcement.",
      image: Leg,
    },
    {
      name: "STATUTORY COMPLIANCE",
      desc: "Statutory Compliance Management Framework fosters a secure and structured delivery of various compliance services, in alignment with highly strategic and tactical functions for end to end solutions and services.",
      image: Com,
    },
    {
      name: "LICENCE WORK",
      desc: "We provide one stop solutions to our clients dealing with Labour department, Factories department, Pollution Control Board, Fire Department, Health Department, and Corporation handling complete list of Registration and renewal of license.",
      image: Lic,
    },
  ];
  return (
    <div className="py-10 px-10 md:px-5 lg:px-28 container mx-auto grid md:grid-cols-3 gap-5">
      {service.map((s) => {
        return (
          <div
            onClick={() => history.push("/service")}
            key={s.name}
            className="rounded cursor-pointer overflow-hidden shadow-lg  transition duration-500 ease-in-out transform hover:-translate-y-1 border-b-4 border-2 border-t-8 border-blue-700"
          >
            <img
              className="w-full object-cover mx-auto"
              src={s.image}
              alt={s.name}
              style={{ height: "150px" }}
            />
            <div className="px-6 py-4 overflow-hidden border-blue-700 border-t">
              <div className="font-bold text-xl mb-2 text-center text-blue-700 uppercase">
                {s.name}
              </div>
              <p className="text-gray-700 text-base line-clamp-4">{s.desc}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
